body{
    margin: 0;
    padding: 0;
    /* background-color: red; */
    font-family: Arial, Helvetica, sans-serif;
}
p, div{
    word-break: break-word;
}
ul{
    padding: 0;
    margin: 0;
}
li{
    padding: 0;
    margin: 0;
    list-style: none;
}
hr.ag-divider {
    width: 87%;
    border: 0;
    height: 3px;
    background: #000;
    background-image: linear-gradient(to bottom, #000, #fff9);
}
.map{
    background-color: #e7e6d9;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
}
.ag-text{
    font-size: 13px;
}

.ag-close{
    background-image: url('imgs/composer-slider-close.png');
    width: 16px;
    height: 16px;
    margin: 10px;
    background-position: bottom;
    cursor: pointer;
}
.ag-close.reverse{
    background-position: top;
}
.ag-close:hover, .ag-close:focus{
    background-position: top;
}
.ag-close.reverse:hover, .ag-close.reverse:focus{
    background-position: bottom;
}
/* START LOGO */

.logo .ankageo{
    position: fixed;
    right: 0;
    bottom: 16px;
    z-index: 9;
    width: 72px;
    margin: 5px;
}
.logo .other{
    width: 60px;
    /* height: 70px; */
    z-index: 9;
    position: fixed;
    right: 0;
    margin: 15px;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
}
.ag-icon{
    background-repeat: no-repeat;
}
/* END LOGO */


/* START TOP MENU */

#main-header{
    top: 0;
    z-index: 90;
    position: absolute;
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, 0);

}
#main-header ul.ag-top-menu{
    justify-content: center;
    background-color: #444f63;
    display: flex;
    list-style: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: #3acde1 solid 4px;
    display: flex;
    flex-wrap: wrap;
}
#main-header ul.ag-top-menu.left{
    box-shadow: inset -10px 0px 10px rgba(0,0,0,0.3);
    box-shadow: inset -10px 0px 10px rgba(0,0,0,0.3);
    transform: translateX(5px)
}
#main-header ul.ag-top-menu.right{
    width: 56px;
    background-color: #ffb901;
    border-bottom: #c89100 solid 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 4px;
}
#main-header a{
    text-decoration: none;
}

ul.ag-top-menu .ag-top-menu-item{
    display: flex;
    flex-direction: column;
    margin: 4px;
    margin-left: 9px; /* Bunun sebebi sol taraftaki menünün bu menünün üzerine çıkartılmasından dolayıdır 5px üzerine çıkartılıyor*/
    align-items: center;
    cursor: pointer;
    width: 60px;
    padding: 3px;
    justify-content: center;
    border-radius: 3px;
    position: relative;
}

.ag-top-menu-item .ag-icon, .ag-left-menu-item .ag-icon{
    width: 28px;
    height: 25px;
    background-position: center;
    /* transition-duration: 0.15s; */
}


.ag-top-menu.left .ag-top-menu-item:not(.ag-active):hover .ag-icon, .ag-left-menu-item:not(.ag-active):hover .ag-icon  {
    background-position: top;
    /* transition-duration: 0.15s; */
}
.ag-top-menu-item.ag-active .ag-icon, .ag-left-menu-item.ag-active .ag-icon{
    background-position: bottom;
}
.ag-top-menu-item .ag-icon.save {
    background-image: url('imgs/save.png')
}
.ag-top-menu-item .ag-icon.folder {
    background-image: url('imgs/folder.png')
}
.ag-top-menu-item .ag-icon.info {
    background-image: url('imgs/info.png')
}
.ag-top-menu-item .ag-icon.location {
    background-image: url('imgs/pin.png')
}
.ag-top-menu-item .ag-icon.measure {
    background-image: url('imgs/cetvel.png')
}
.ag-top-menu-item .ag-icon.area {
    background-image: url('imgs/area_calc.png')
}
.ag-top-menu.right .ag-top-menu-item .ag-icon.slider {
    background-image: url('imgs/slider.png')
}
/* START SLIDER */
.slider-header{
    top: 0;
    z-index: 90;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translate(-50%, 0);
    background: #FFB900;
    border-bottom: #C79100 solid 3px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 2px;
    padding-left: 30px;
}

/* END SLIDER */
/* END TOP MENU */


/* START LEFT MENU */

#leftpane{
    top: 2px;
    left: 0;
    z-index: 10;
    position: absolute;
    display: flex;
    align-items:flex-start;
    align-content:flex-start;
}

.left-menu{
    /* padding: 10px; */
    display: flex;
    flex-direction: column;
    background-color: #424c5e;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-right: #3acde1 solid 3px;
    
}
.ag-left-menu-item{
    display: flex;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}
.ag-left-menu-item .info{
    background-image: url('imgs/info.png')
}
.ag-left-menu-item .layers{
    background-image: url('imgs/layers.png')
}
.ag-left-menu-item .settings{
    background-image: url('imgs/settings.png')
}


/* START NESTED MENU */
.nested-menu{
    min-width: 200px;
    /* min-height: 200px; */
    background: #444f63;
    border-radius: 5px;
    box-shadow: inset 10px 0px 10px rgba(0,0,0,0.3);
    position: relative;
    color: #a3a7b1;
    padding: 7px;
    /* padding-left: 16px; */

}
div.layer-menu{
    width: 100%;
    padding: 10px;
    padding-top: 15px;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    margin: auto;
    background: url('imgs/earth.png');
    background-repeat: no-repeat;
    background-position-x: right;
}
.nested-menu div.layer-menu div.add-layer{
    padding: 5px;
    padding-bottom: 15px;
}

.nested-menu .layer-menu ul.terrain-menu li{
    padding-top: 5px;
    padding-bottom: 5px;
}
div.layer-menu div.add-layer{
    color: #3acde1;
}

ul.layers{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 15px;
    max-height: 363px;
    margin-bottom: 15px;
}
.layers .layers-scroll{
    max-height: 200px;
}
/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #a6aab3; 
    border-radius: 10px;
    border-left: 3px solid #444f63;
    border-right: 3px solid #444f63;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #a6aab3; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c3c4cb; 
}
li.layer{
    display: flex;
    flex-direction: column;
    margin: 5px;
    margin-bottom: 10px;
    
}
ul.layers li.layer div.checkbox{
    margin-bottom: 5px;
}
ul.settings{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    margin: 4px 15px 8px 37px;
}
ul.settings li{
    margin: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.ag-icon.layer-custom-filter{
    width: 16px;
    height: 16px;
    /* background: white; */
    background-image: url('imgs/layer-brush.png');
}
.ag-icon.layer-filter{
    width: 18px;
    height: 17px;
    background-image: url('imgs/layer-filter.png');
}
.ag-icon.layer-focus{
    width: 16px;
    height: 16px;
    background-image: url('imgs/layer-focus.png')
}
.ag-icon.layer-analyze{
    width: 28px;
    height: 16px;
    background-image: url('imgs/layer-analyze.png')
}

/* END NESTED MENU */
/* END LEFT MENU */

/* START COLORS */
.ag-color-primary{
    color: #c3c4cb !important;
}
.ag-color-secondary{
    color: #9ba2ab !important;
}
/* END COLORS */


/* START MEDIA */
@media(max-width:960px){
    #leftpane{
        float: left;
        position: relative;
    }
    #main-header{
        position: relative;
    }
}
/* END MEDIA */



/* START COMPARE MENU */
.ag-compare-service-menu{
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 80;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    transition: all .5s ease-in-out;
}
.ag-compare-service-menu .ag-displayer{
    width: 50px;
    position: relative;
    background: #454F63;
    border-left: #FFB900 solid 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    cursor: pointer;
    user-select: none;
}

.ag-compare-service-menu .ag-displayer .ag-rotate{
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    transform: rotate(180deg);
    white-space: nowrap;
    padding: 8px 0;
    writing-mode: vertical-lr;
    
}
.ag-compare-service-menu .ag-services{
    position: relative;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    min-height: 400px;
    background: #454F63;;
    border-radius: 4px;
    box-shadow: inset -10px 0px 10px rgba(0,0,0,0.3);
}
.ag-compare-service-menu.ag-active {
    transform: translateX(calc(100% - 50px));
}
/* END COMPARE MENU */


/* START CESIUM SETTINGS */
.cesium-credit-expand-link{
    display: none !important;
}
/* END CESIUM SETTINGS */


/* INNER IMG BACGROUNDS */

.ag-ankageo-logo-container > a > div.logo-pure{
    background-image: url('imgs/ankageo-logo-pure.png');
}
.ag-ankageo-logo-container > a > div.logo-blur{
    background-image: url('imgs/ankageo-logo-blur.png');
}
div.other-company-logo-container > a > div.ag-other-logo{
    background-image: url('imgs/b_b_mini_tiny.png')
}
/* END OF INNER IMG BACGROUNDS */


/* MAP CONTAINTER START */
.mapContainer {
    position:  fixed; 
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
}
.mapContainer > * {
    width: 50%;
    flex-grow: 1;
}
/* MAP CONTAINTER END */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html, body, #root {
  width: 100vw;
  height: 100vh;
  margin: 0;
}